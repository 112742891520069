const API_BASE_URL = 'https://www.atail.cc/api'; // 确保这里的端口号与后端服务器匹配

export async function uploadAndProcessImage(file) {
  const token = localStorage.getItem('token');
  const loginTime = localStorage.getItem('loginTime');
  
  if (!token || !loginTime || Date.now() - parseInt(loginTime) > 24 * 60 * 60 * 1000) {
    localStorage.removeItem('token');
    localStorage.removeItem('loginTime');
    throw new Error('Login has expired. Please log in again');
  }

  const formData = new FormData();
  formData.append('image', file);

  const response = await fetch(`${API_BASE_URL}/process_image`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Picture upload or processing failed');
  }

  const result = await response.json();
  return {
    original: `${API_BASE_URL}${result.original}`,
    processed: `${API_BASE_URL}${result.processed}`
  };
}

export async function sendEmail(email, templateData) {
  const response = await fetch(`${API_BASE_URL}/send_email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, templateData }),
  });

  if (!response.ok) {
    throw new Error('Email sending failed');
  }

  return await response.json();
}

export async function register(email, password, recaptchaToken) {
  const response = await fetch(`${API_BASE_URL}/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, recaptchaToken }),
  });

  const data = await response.json();

  if (!response.ok) {
    if (data.error === '该邮件已被注册') {
      throw new Error('Email already registered');
    }
    throw new Error(data.error || 'Registration failed');
  }

  return data;
}

export async function login(email, password, recaptchaToken) {
  const response = await fetch(`${API_BASE_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, recaptchaToken }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || 'Login failed');
  }

  localStorage.setItem('token', data.token);
  localStorage.setItem('loginTime', Date.now().toString());

  return data;
}

export async function forgotPassword(email) {
  const response = await fetch(`${API_BASE_URL}/forgot_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  const data = await response.json();

  if (!response.ok) {
    if (data.error === '该邮箱还未注册') {
      throw new Error('This email is not registered');
    }
    throw new Error(data.error || 'Failed to send password reset email');
  }

  return data;
}

export async function resetPassword(token, newPassword) {
  const response = await fetch(`${API_BASE_URL}/reset_password/${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ new_password: newPassword }),
  });

  if (!response.ok) {
    throw new Error('Password reset failed');
  }

  return await response.json();
}

export async function adminLogin(username, password) {
  const response = await fetch(`${API_BASE_URL}/admin/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || '管理员登录失败');
  }

  return data;
}

export async function getUsers(token) {
  const response = await fetch(`${API_BASE_URL}/admin/users`, {
    method: 'GET',
    headers: {
      'Authorization': token,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to get user list');
  }

  return data;
}

export async function deleteUser(token, userId) {
  const response = await fetch(`${API_BASE_URL}/admin/users/${userId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': token,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Failed to delete user');
  }

  return data;
}
