import React, { useRef } from 'react';
import { uploadAndProcessImage } from '../utils/api';

function ImageUploader({ onUploadStart, onImageProcessed, isLoggedIn, setShowLoginModal }) {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return;
    }
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      onUploadStart();
      try {
        const result = await uploadAndProcessImage(file);
        onImageProcessed(result);
      } catch (error) {
        console.error('Image processing failure:', error);
        alert('Image processing failure: ' + (error.message || 'unknown error'));
      }
    }
  };

  return (
    <div className="image-uploader">
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <button onClick={handleUploadClick} className="upload-button">Upload image</button>
    </div>
  );
}

export default ImageUploader;
