import React, { useState, useEffect, useRef } from 'react';

const UserMenu = ({ userEmail, onLogout }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        iconRef.current && !iconRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="user-menu-container">
      <div className="user-menu" onClick={toggleDropdown} ref={iconRef}>
        <img 
          src="/images/login.svg" 
          alt="User" 
          className="user-icon"
        />
      </div>
      {showDropdown && (
        <div 
          ref={dropdownRef}
          className="dropdown-menu"
        >
          <div className="user-email">{userEmail}</div>
          <div className="free-period">Free period 😊</div>
          <button onClick={onLogout}>logout</button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
