import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <img src={`${process.env.PUBLIC_URL}/images/bottom.svg`} alt="AIsoup Lab" className="footer-logo" />
    </footer>
  );
}

export default Footer;

