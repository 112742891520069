import React, { useState, useRef, useEffect } from 'react';
import ImageUploader from './components/ImageUploader';
import ImageComparison from './components/ImageComparison';
import LoadingSpinner from './components/LoadingSpinner';
import { uploadAndProcessImage, sendEmail, register, login, forgotPassword } from './utils/api';
import './styles/main.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ResetPassword from './components/ResetPassword';
import AdminLogin from './components/AdminLogin';
import UserManagement from './components/UserManagement';
import ReCaptcha from './components/ReCaptcha';
import Toast from './components/Toast';
import UserMenu from './components/UserMenu';
import Footer from './components/Footer';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [processedImage, setProcessedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [modalMode, setModalMode] = useState('login');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [adminToken, setAdminToken] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [toast, setToast] = useState({ show: false, message: '' });
  const [userEmail, setUserEmail] = useState('');
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedEmail = localStorage.getItem('userEmail');
    const loginTime = localStorage.getItem('loginTime');
    
    if (token && storedEmail && loginTime) {
      const currentTime = Date.now();
      const loginDuration = currentTime - parseInt(loginTime);
      if (loginDuration < 24 * 60 * 60 * 1000) { // 24小时内
        setIsLoggedIn(true);
        setUserEmail(storedEmail);
      } else {
        // 登录已过期,清除本地存储
        localStorage.removeItem('token');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('loginTime');
      }
    }
  }, []);

  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    const loginTime = localStorage.getItem('loginTime');
    if (token && loginTime) {
      const currentTime = Date.now();
      const loginDuration = currentTime - parseInt(loginTime);
      if (loginDuration >= 24 * 60 * 60 * 1000) { // 24小时
        localStorage.removeItem('token');
        localStorage.removeItem('loginTime');
        setIsLoggedIn(false);
        setToast({ show: true, message: 'Login expired, please log in again' });
      } else {
        setIsLoggedIn(true); // 如果登录未过期，设置为已登录状态
      }
    } else {
      setIsLoggedIn(false); // 如果没有token或loginTime，设置为未登录状态
    }
  };

  useEffect(() => {
    checkLoginStatus(); // 初检查
    const intervalId = setInterval(checkLoginStatus, 60000); // 每分钟检查一次

    return () => clearInterval(intervalId); // 清理定时器
  }, []);

  const handleImageProcessed = (result) => {
    setProcessedImage(result);
    setIsLoading(false);
  };

  const handleUpload = async (file) => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return;
    }
    
    if (file) {
      setIsLoading(true);
      try {
        console.log('Starting image upload...');
        const result = await uploadAndProcessImage(file);
        console.log('Image processing result:', result);
        handleImageProcessed(result);
      } catch (error) {
        console.error('Image processing failed:', error);
        setStatusMessage('Image processing failed: ' + (error.message || 'Unknown error'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReupload = () => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error('File input element not found');
    }
  };

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'processed_image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download image:', error);
      alert('Failed to download image: ' + (error.message || 'Unknown error'));
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(forgotPasswordEmail);
      setToast({ show: true, message: response.message });
      setModalMode('login');
    } catch (error) {
      console.error('Failed to send password reset email:', error);
      setToast({ show: true, message: error.message });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setToast({ show: true, message: 'Passwords do not match' });
      return;
    }
    if (!isRecaptchaVerified) {
      setToast({ show: true, message: 'Please complete the reCAPTCHA verification' });
      return;
    }
    try {
      await register(email, password, recaptchaToken);
      setIsLoggedIn(true);
      setShowLoginModal(false);
      setToast({ show: true, message: 'Registration successful' });
    } catch (error) {
      setToast({ show: true, message: error.message });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isRecaptchaVerified) {
      setToast({ show: true, message: 'Please complete the reCAPTCHA verification' });
      return;
    }
    try {
      const response = await login(email, password, recaptchaToken);
      setIsLoggedIn(true);
      setUserEmail(email);
      localStorage.setItem('userEmail', email);
      setToast({ show: true, message: 'Login successful' });
      setShowLoginModal(false);
    } catch (error) {
      console.error('Login failed:', error);
      setToast({ show: true, message: error.message || 'Login failed' });
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginTime');
    localStorage.removeItem('userEmail');
    setIsLoggedIn(false);
    setUserEmail('');
    setShowDropdown(false);
    setProcessedImage(null);
    setStatusMessage('');
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const isMobile = window.innerWidth <= 480;

  const handleLogoClick = () => {
    window.open('https://www.atail.cc', '_blank');
  };

  return (
    <Router>
      <div className="app">
        <div className="logo-container" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="Atail Logo" className="logo" />
          <div className="logo-text">
            <h2>Atail</h2>
            <p>remove background</p>
          </div>
        </div>
        <Routes>
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route path="/admin" element={
            adminToken ? 
              <UserManagement token={adminToken} /> : 
              <AdminLogin onLoginSuccess={setAdminToken} />
          } />
          <Route path="/" element={
            <>
              {isLoggedIn ? (
                <div className="user-menu-container">
                  <div className="user-menu" onClick={toggleMenu}>
                    <img src="/images/login.svg" alt="User" className="user-icon" />
                  </div>
                  <div className={`dropdown-menu ${isMenuVisible ? '' : 'hidden'}`}>
                    <div className="user-email">{userEmail}</div>
                    <div className="free-period">Free period 😊</div>
                    <button onClick={handleLogout}>Logout</button>
                  </div>
                </div>
              ) : (
                <div className="auth-buttons">
                  <button className="login-button" onClick={() => {setShowLoginModal(true); setModalMode('login');}}>
                    Log in
                  </button>
                  <button className="signup-button" onClick={() => {setShowLoginModal(true); setModalMode('register');}}>
                    Sign up
                  </button>
                </div>
              )}
              <h1>Remove background</h1>
              <p className="subtitle">Just one step,AI is a magic wand that quickly removes backgrounds</p>
              
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleUpload(e.target.files[0])}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />

              {!processedImage && !isLoading && (
                <>
                  <div className="hero-image">
                    <img src={`${process.env.PUBLIC_URL}/images/background.jpg`} alt="抠图示例" />
                  </div>
                  <ImageUploader
                    onUploadStart={() => setIsLoading(true)}
                    onImageProcessed={handleImageProcessed}
                    isLoggedIn={isLoggedIn}
                    setShowLoginModal={setShowLoginModal}
                  />
                </>
              )}
              {isLoading && <LoadingSpinner />}
              {processedImage && (
                <div className="result-container fade-in">
                  <ImageComparison
                    originalImage={processedImage.original}
                    processedImage={processedImage.processed}
                  />
                  <div className="button-group">
                    <button onClick={handleReupload}>Re-upload</button>
                    <button onClick={() => handleDownload(processedImage.processed)}>download</button>
                  </div>
                </div>
              )}
              {showLoginModal && (
                <div className="login-modal" onClick={() => setShowLoginModal(false)}>
                  <div className="login-modal-content" onClick={(e) => e.stopPropagation()}>
                    <span className="close-btn" onClick={() => setShowLoginModal(false)}>&times;</span>
                    {modalMode === 'login' && (
                      <>
                        <h2>Login</h2>
                        <form onSubmit={handleLogin}>
                          <input 
                            type="email" 
                            placeholder="Email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <input 
                            type="password" 
                            placeholder="Password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <ReCaptcha
                            sitekey="6LeSclMqAAAAAMJO-y30avvqRuIyRt-JpCPW4vcE"
                            onChange={(token) => {
                              setRecaptchaToken(token);
                              setIsRecaptchaVerified(true);
                            }}
                          />
                          <button type="submit">Login</button>
                        </form>
                        <div className="modal-links">
                          <span onClick={() => setModalMode('register')}>Register</span>
                          <span onClick={() => setModalMode('forgotPassword')}>Forgot password?</span>
                        </div>
                      </>
                    )}
                    {modalMode === 'register' && (
                      <>
                        <h2>Register</h2>
                        <form onSubmit={handleRegister}>
                          <input 
                            type="email" 
                            placeholder="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required
                          />
                          <input 
                            type="password" 
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required
                          />
                          <input 
                            type="password" 
                            placeholder="Confirm password" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            required
                          />
                          <ReCaptcha
                            sitekey="6LeSclMqAAAAAMJO-y30avvqRuIyRt-JpCPW4vcE"
                            onChange={(token) => {
                              setRecaptchaToken(token);
                              setIsRecaptchaVerified(true);
                            }}
                          />
                          <button type="submit">Register</button>
                        </form>
                        <div className="modal-links">
                          <span onClick={() => setModalMode('login')}>Already have an account? Login</span>
                        </div>
                      </>
                    )}
                    {modalMode === 'forgotPassword' && (
                      <>
                        <h2>Forgot password</h2>
                        <form onSubmit={handleForgotPassword}>
                          <input 
                            type="email" 
                            placeholder="Email" 
                            value={forgotPasswordEmail} 
                            onChange={(e) => setForgotPasswordEmail(e.target.value)} 
                          />
                          <button type="submit">Reset password</button>
                        </form>
                        <div className="modal-links">
                          <span onClick={() => setModalMode('login')}>Back to login</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {toast.show && (
                <Toast message={toast.message} onClose={() => setToast({ show: false, message: '' })} />
              )}
              {isLoggedIn && (
                <UserMenu 
                  userEmail={userEmail} 
                  onLogout={handleLogout} 
                />
              )}
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
