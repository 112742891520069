import React, { useEffect, useRef } from 'react';

const ReCaptcha = ({ onChange, sitekey }) => {
  const captchaRef = useRef(null);
  const renderedRef = useRef(false);

  useEffect(() => {
    if (window.grecaptcha && captchaRef.current && !renderedRef.current) {
      window.grecaptcha.ready(() => {
        try {
          window.grecaptcha.render(captchaRef.current, {
            sitekey: sitekey,
            callback: onChange,
          });
          renderedRef.current = true;
        } catch (error) {
          if (error.message !== 'reCAPTCHA has already been rendered in this element') {
            console.error('reCAPTCHA rendering error:', error);
          }
        }
      });
    }
  }, [onChange, sitekey]);

  return <div ref={captchaRef}></div>;
};

export default ReCaptcha;