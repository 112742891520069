import React, { useState, useEffect } from 'react';
import { getUsers, deleteUser } from '../utils/api';

const styles = `
  .user-management table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  .user-management th,
  .user-management td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  .user-management th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .user-management tr:hover {
    background-color: #f0f0f0;
  }
`;

function UserManagement({ token }) {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getUsers(token);
      setUsers(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('确定要删除这个用户吗？')) {
      try {
        await deleteUser(token, userId);
        fetchUsers();
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <>
      <style>{styles}</style>
      <div className="user-management">
        <h2>用户管理</h2>
        {error && <p className="error">{error}</p>}
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>邮箱</th>
              <th>注册时间</th>
              <th>最后使用时间</th>
              <th>使用次数</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.created_at}</td>
                <td>{user.last_use || '从未使用'}</td>
                <td>{user.usage_count}</td>
                <td>
                  <button onClick={() => handleDeleteUser(user.id)}>删除</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UserManagement;
