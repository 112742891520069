import React from 'react';

function ImageComparison({ originalImage, processedImage }) {
  return (
    <div className="image-comparison">
      <div className="comparison-container">
        <img src={originalImage} alt="Original" className="comparison-image" />
        <div className="processed-image-container">
          <div className="checkerboard-background"></div>
          <img src={processedImage} alt="Processed" className="processed-image" />
        </div>
      </div>
    </div>
  );
}

export default ImageComparison;