import React, { useState, useEffect } from 'react';
import { adminLogin } from '../utils/api';

function AdminLogin({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lockoutTime, setLockoutTime] = useState(null);

  useEffect(() => {
    const storedLockoutTime = localStorage.getItem('adminLockoutTime');
    if (storedLockoutTime) {
      setLockoutTime(new Date(storedLockoutTime));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (lockoutTime && new Date() < lockoutTime) {
      setError('登录已被暂停。请稍后再试。');
      return;
    }
    try {
      const data = await adminLogin(username, password);
      onLoginSuccess(data.token);
      setLoginAttempts(0);
      localStorage.removeItem('adminLockoutTime');
    } catch (error) {
      setLoginAttempts(prev => prev + 1);
      if (loginAttempts + 1 >= 3) {
        const newLockoutTime = new Date(new Date().getTime() + 15 * 60000);
        setLockoutTime(newLockoutTime);
        localStorage.setItem('adminLockoutTime', newLockoutTime.toISOString());
        setError('登录已被暂停15分钟。请稍后再试。');
      } else {
        setError('用户名或密码错误');
      }
    }
  };

  return (
    <div className="admin-login">
      <h2>管理员登录</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="用户名"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="密码"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">登录</button>
      </form>
    </div>
  );
}

export default AdminLogin;
